<template>
  <page-record-offline v-if="isRecordOffline"></page-record-offline>
  <div v-else-if="isRecordNotExist">
    <slot name="page-not-found"></slot>
  </div>
  <div class="source-details-page" v-else>
    <div class="limited_content">
      <mcr-loading-indicator v-if="loading" :loading="true"></mcr-loading-indicator>
      <div class="details-content" v-else>
        <div class="desktop-photos">
          <external-source-logo v-if="showExternalLogo" :img-src="cover" :url="externalSourceUrl">
          </external-source-logo>
          <card-with-ribbon v-else :text="imageText" @click.native="onImageClick">
            <img :src="cover" v-if="cover" />
            <img :src="placeholder" v-else class="placeholder" />
          </card-with-ribbon>
        </div>
        <div class="details">
          <div class="header">
            <h5>{{ title }}</h5>
            <div class="source-type-wrapper">
              <record-type :type-name="recordTypeLabel"></record-type>
            </div>
            <hr />
            <div class="action-buttons">
              <mcr-button-router-link v-if="sourceDetailsState.images_count" :to="sourceViewerRoute"
                >View Source</mcr-button-router-link
              >
              <mcr-button v-if="isExternalSource && externalSourceUrl && !isTemplate" :href="externalSourceUrl"
                >Open in Partner Site</mcr-button
              >
              <mcr-button v-if="userIsStaffState" :href="adminEditSourceLink" target="_blank" class="admin transparent"
                >Open in admin</mcr-button
              >
            </div>
          </div>

          <div class="mobile-photos">
            <external-source-logo
              v-if="showExternalLogo"
              :img-src="cover"
              :url="externalSourceUrl"
            ></external-source-logo>
            <card-with-ribbon v-else :text="imageText" @click.native="onImageClick">
              <img :src="cover" v-if="cover" />
              <img :src="placeholder" v-else class="placeholder" />
            </card-with-ribbon>
          </div>

          <summary-text
            v-if="sourceDetailsState.summary"
            :summary="sourceDetailsState.summary"
            :summary-preview="sourceDetailsState.summary_preview"
            class="sub-section"
          ></summary-text>

          <div class="persons-indexed sub-section" v-if="sourceDetailsState.mentions_count">
            <b>Total persons indexed:</b><span> {{ sourceDetailsState.mentions_count }}</span>
          </div>

          <div v-if="sourceDetailsState.mentions_count" class="section">
            <section-search-records :search-route="searchAllRoute" @submit="searchMentions"></section-search-records>
          </div>
          <div v-else-if="sourceDetailsState.has_ocr" class="section">
            <section-search-ocr @submit="searchOcr"></section-search-ocr>
          </div>

          <details-table :source="sourceDetailsState" :is-template="isTemplate"></details-table>
          <p class="supplemental" v-if="sourceDetailsState.data_provider" v-prerender-if="'data_provider'">
            Source made available courtesy of
            <a :href="sourceDetailsState.data_provider_url" target="_blank" rel="nofollow">{{
              sourceDetailsState.data_provider
            }}</a>
          </p>
          <slot name="info-cta"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import RecordType from '@common/elements/icons/RecordType';
import ExternalSourceLogo from '@common/elements/images/ExternalSourceLogo';
import CardWithRibbon from '@common/elements/layouts/CardWithRibbon';
import PageRecordOffline from '@common/pages/pageRecordOffline';
import {TAB_ID_FULL_TEXT, TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {getSourceAdminUrl} from '@common/utils/utils.admin';
import {getPlaceLabelBySourceType} from '@common/utils/utils.sources';
import {mapGetters} from 'vuex';

import DetailsTable from './DetailsTable';
import SectionSearchOcr from './SectionSearchOcr';
import SectionSearchRecords from './SectionSearchRecords';
import SummaryText from './SummaryText';
import sourceData from './prerender/sourceData';

export default {
  metaInfo() {
    const source = this.sourceDetailsState;
    const placeLabel = getPlaceLabelBySourceType(source.type);
    const publicationPlaceStr = source.place ? `${placeLabel}: ${source.place.pinyin}.` : '';
    const datePublished = source.publication_year ? `Date Published: ${source.publication_year}.` : '';
    const imageMeta =
      source && source.cover
        ? [
            {
              vmid: 'og:image:secure_url',
              property: 'og:image:secure_url',
              content: source.cover,
            },
            {
              vmid: 'og:image',
              property: 'og:image',
              content: source.cover,
            },
            {
              vmid: 'image',
              name: 'image',
              property: 'image',
              content: source.cover,
            },
            {
              vmid: 'twitter:image',
              name: 'twitter:image',
              property: 'twitter:image',
              content: source.cover,
            },
          ]
        : [];
    return {
      title: this.title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `Title: ${source.title_en || ''} ${
            source.title_ch || ''
          }. ${publicationPlaceStr} ${datePublished} Record type: ${source.type_display}`,
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          name: 'og:title',
          content: this.title,
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        ...imageMeta,
      ],
    };
  },
  data() {
    return {
      isTemplate: false,
      sourceId: this.$route.params.sourceId,
      loading: true,
      isRecordOffline: false,
      isRecordNotExist: false,
    };
  },
  created() {
    if (this.$route.meta.isPrerenderTemplate) {
      this.isTemplate = true;
      this.$store.commit('setSourceDetailsState', sourceData);
      this.sourceId = sourceData.id;
      this.loading = false;
      return;
    }
    if (window.__PRERENDERED_STATE__) {
      this.$store.commit('setSourceDetailsState', window.__PRERENDERED_STATE__);
      this.sourceId = window.__PRERENDERED_STATE__.id;
      window.__PRERENDERED_STATE__ = null;
      this.loading = false;
      return;
    }
    this.$store
      .dispatch('fetchSourceDetailsAction', {id: this.sourceId})
      .then(source => {
        if (source.type === 'zupu') {
          this.$router.replace({name: 'search-tool-zupu-detail', params: {zupuId: source.zupu_id}});
          return;
        }
        this.loading = false;
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.is_offline) {
          this.isRecordOffline = true;
        } else {
          this.isRecordNotExist = true;
        }
      });
    this.$store.dispatch('fetchMentionsOptionsAction');
  },
  computed: {
    ...mapGetters(['sourceDetailsState', 'mentionsOptionsState', 'userIsStaffState']),
    cover() {
      return this.sourceDetailsState.cover;
    },
    placeholder() {
      return this.$getAsset('/assets/mcr-logo-grey-light.png');
    },
    title() {
      return this.sourceDetailsState.full_title;
    },
    isExternalSource() {
      return this.sourceDetailsState.is_external;
    },
    showExternalLogo() {
      return this.isExternalSource && this.sourceDetailsState.images_count <= 1;
    },
    externalSourceUrl() {
      return this.sourceDetailsState.external_attachments_url || this.sourceDetailsState.external_url;
    },
    imageText() {
      return this.sourceDetailsState.has_images ? 'View images' : '';
    },
    sourceViewerRoute() {
      return {name: 'source-viewer', params: {sourceId: this.sourceId}};
    },
    adminEditSourceLink() {
      return getSourceAdminUrl(this.sourceId);
    },
    searchAllRoute() {
      return {name: 'search-all-records', query: {source_id: this.sourceId, tab: TAB_ID_INDEXED_RECORDS}};
    },
    recordTypeLabel() {
      const type = this.sourceDetailsState.type_display;
      const country = this.sourceDetailsState.place.pinyin;
      return type && country ? `${country}, ${type}` : type || country;
    },
  },
  methods: {
    onImageClick() {
      this.goToViewer();
    },
    goToViewer() {
      if (this.sourceDetailsState.images_count) {
        this.$router.push(this.sourceViewerRoute);
      }
    },
    searchMentions({firstName, lastName}) {
      const query = {first_name: firstName, surname: lastName, source_id: this.sourceId, tab: TAB_ID_INDEXED_RECORDS};
      return this.$router.push({name: 'search-all-records', query});
    },
    searchOcr({firstName, lastName}) {
      const query = {q: firstName, last_name: lastName, source_id: this.sourceId, tab: TAB_ID_FULL_TEXT};
      return this.$router.push({name: 'search-all-records', query});
    },
  },
  components: {
    McrButtonRouterLink,
    McrButton,
    ExternalSourceLogo,
    SectionSearchRecords,
    SectionSearchOcr,
    CardWithRibbon,
    SummaryText,
    DetailsTable,
    PageRecordOffline,
    RecordType,
  },
  name: 'SourceDetailsPage',
};
</script>

<style lang="scss" scoped>
.source-details-page {
  .details-content {
    display: flex;
    flex-direction: row;

    .desktop-photos {
      width: 40%;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      margin-right: 48px;
    }

    .mobile-photos {
      display: none;
    }
    .card-with-ribbon {
      background: $background-second-alternate-color;
      box-shadow: $box-shadow;
      img.placeholder {
        object-fit: scale-down;
      }
    }
    &::v-deep .card-with-ribbon {
      align-self: center;
      cursor: pointer;
      font-size: 0.8em;
      min-width: 100%;

      .link-icon {
        transform: rotate(-45deg);
        margin-bottom: 5px;
      }
    }

    .details {
      flex-grow: 1;
      min-width: 0;

      .header {
        h5 {
          margin: 0;
        }

        .source-type-wrapper {
          margin: 8px 0 16px;
        }
      }

      .action-buttons {
        display: flex;
        flex-direction: row;
        padding: 10px 0;
        > *:not(:last-child) {
          margin-right: 15px;
          min-width: 170px;
        }
      }
    }

    .section {
      margin-top: 40px;
    }
    .sub-section {
      display: block;
      margin-top: 18px;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
      .desktop-photos {
        display: none;
      }
      .mobile-photos {
        display: block;
        margin: 30px 0 50px;
      }

      .details {
        .action-buttons {
          flex-direction: column;
          > *:not(:last-child) {
            margin-bottom: 15px;
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
